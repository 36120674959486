import React, { useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";

const Meter = (props) => {
  let { setSchedule, schedule } = props;
  const [value, setValue] = useState(0);
  const [showTempStatus, setShowTempStatus] = useState("OFF");

  let duplicateSchedule = { ...schedule };

  const increaseTemperature = () => {
    if (value <= 30) {
      duplicateSchedule.temperature = "30";
      setSchedule({ ...duplicateSchedule });
      setValue(30);
      setShowTempStatus("Warm");
    }
    if (value === 30) {
      setValue(35);
      duplicateSchedule.temperature = "35";
      setSchedule({ ...duplicateSchedule });
      setShowTempStatus("WARM+");
    }
    if (value === 35) {
      setValue(40);
      duplicateSchedule.temperature = "40";
      setSchedule({ ...duplicateSchedule });
      setShowTempStatus("HOT");
    }
    if (value === 40) {
      setValue(45);
      duplicateSchedule.temperature = "45";
      setSchedule({ ...duplicateSchedule });
      setShowTempStatus("HOT+");
    }
    if (value === 45) {
      setValue(55);
      duplicateSchedule.temperature = "55";
      setSchedule({ ...duplicateSchedule });
      setShowTempStatus("HOT ++");
    }
    if (value === 55) {
      setValue(65);
      duplicateSchedule.temperature = "65";
      setSchedule({ ...duplicateSchedule });
      setShowTempStatus("VERY HOT");
    }
  };
  const decreaseTemperature = () => {
    if (value === 30) {
      duplicateSchedule.temperature = "0";
      setSchedule({ ...duplicateSchedule });
      setValue(0);
      setShowTempStatus("OFF");
    }
    if (value === 35) {
      duplicateSchedule.temperature = "30";
      setSchedule({ ...duplicateSchedule });
      setValue(30);
      setShowTempStatus("Warm");
    }
    if (value === 40) {
      setValue(35);
      duplicateSchedule.temperature = "35";
      setSchedule({ ...duplicateSchedule });
      setShowTempStatus("WARM+");
    }
    if (value === 45) {
      setValue(40);
      duplicateSchedule.temperature = "40";
      setSchedule({ ...duplicateSchedule });
      setShowTempStatus("HOT");
    }
    if (value === 55) {
      setValue(45);
      duplicateSchedule.temperature = "45";
      setSchedule({ ...duplicateSchedule });
      setShowTempStatus("HOT+");
    }
    if (value === 65) {
      setValue(55);
      duplicateSchedule.temperature = "55";
      setSchedule({ ...duplicateSchedule });
      setShowTempStatus("HOT++");
    }
  };
  return (
    <>
      <div className="d-flex justify-content-center">
        <ReactSpeedometer
          minValue={0}
          maxValue={65}
          segments={15}
          height={190}
          needleColor={"red"}
          valueTextFontSize={"14"}
          labelFontSize={"12"}
          ringWidth={50}
          segmentColors={[
            "#5217dd",
            "#5217dd",
            "#5217dd",
            "#5217dd",
            "#5217dd",
            "#5217dd",
            "#FFC300",
            "#FFC300",
            "#FF5733",
            "#FF5733",
            "#FF5733",
            "#C70039",
            "#C70039",
            "#900C3F",
            "#900C3F",
          ]}
          currentValueText="${value} &#8451;"
          value={parseInt(value, 10)}
        />
      </div>
      <div className="d-flex justify-content-center pb-1">
        <div className="border w-25 text-center">{showTempStatus}</div>
      </div>
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-purple btn-block mx-1"
          title="Decrease"
          onClick={(e) => {
            decreaseTemperature();
          }}
        >
          -
        </button>
        <button
          className="btn btn-purple btn-block "
          title="Increase"
          onClick={() => {
            increaseTemperature();
          }}
        >
          +
        </button>
      </div>
    </>
  );
};

export default Meter;

import { useState, useContext } from "react";

import { CognitoUser } from "amazon-cognito-identity-js";
import Pool from "../../utils/auth/userPool";

import { Row, Col, Card, Container, Form, Input, Label } from "reactstrap";

// import images
import profileImg from "../../assets/images/profile-img.png";
import { AccountContext } from "../../session/userSession";

const Index = (props) => {
  let { history } = props;

  const intialValues = { value: "" };

  const [code, setCode] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  let duplicateCode = { ...code };
  const { reSendOtp } =
    useContext(AccountContext);

  const submitHandler = (e) => {
    e.preventDefault();

    setFormErrors(validate(code));
  };

  const validate = (code) => {
    let errors = {};

    if (!code.value) {
      errors.code = "Cannot be blank";
    } else if (code.value.length < 6) {
      errors.code = "Password must be 6 characters";
    }
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      var userData = {
        Username: localStorage.getItem("ezgeyser.unconfirmedUser"),
        Pool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.confirmRegistration(code.value, true, function (err, result) {
        if (err) {
          setIsSubmitting(false);
          setErrMessage(err.message);
          setTimeout(() => {
            setErrMessage("");
          }, 1500);
        } else {
          setSuccessMessage("Verfied Successfully");
          setIsSubmitting(false);
          setTimeout(() => {
            setSuccessMessage("");
            history.push("/");
          }, 1500);
        }
      });
    }

    return errors;
  };


  const codeResendHandler = () => {
    var sendCodeTo = localStorage.getItem("ezgeyser.unconfirmedUser");
    reSendOtp(sendCodeTo);
  };

  return (
    <>
      <div className="account-pages min-vh-100 d-flex justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center align-items-center ">
            <Col md={7} lg={5} xl={5}>
              <Card className="overflow-hidden mt-4">
                <div className="bg-purple">
                  <Row>
                    <Col className="col-7">
                      <div className="text-white p-4">
                        <h5>Confirm Your Account!</h5>
                        <p>Check your email for code.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                {errMessage && (
                  <Row className="justify-content-center alert-fail border-success rounded mx-5 mt-1 d-flex p-2">
                    <span className="text-danger text-center">
                      {errMessage}
                    </span>
                  </Row>
                )}
                {successMessage && (
                  <Row className="justify-content-center alert-success  border-success rounded mx-5 mt-2 d-flex p-2">
                    <span className="text-success  text-center">
                      {successMessage}
                    </span>
                  </Row>
                )}
                <div className=" py-4 px-2">
                  <Form className="form-inline " onSubmit={submitHandler}>
                    <Row className="justify-content-center d-flex mb-3 ">
                      <div className=" mx-sm-3 col">
                        <Label className="form-label mb-1">Enter Code</Label>
                        <Input
                          text="Enter Code to verify your account"
                          value={code.value}
                          maxLength="10"
                          className={
                            formErrors.email
                              ? "border border-danger w-100 form-control input-style"
                              : " form-control w-100 input-style"
                          }
                          placeholder="Enter Code"
                          onChange={(e) => {
                            duplicateCode.value = e.target.value;
                            setCode({ ...duplicateCode });
                          }}
                          type="number"
                        />

                        {formErrors.code && (
                          <span className="error text-danger">
                            {formErrors.code}
                          </span>
                        )}
                        <div className="m-0 pt-1 p-0 d-flex justify-content-end">
                          <label
                            className="link"
                            onClick={() => {
                              codeResendHandler();
                            }}
                          >
                            Resend Code ?
                          </label>
                        </div>
                      </div>
                    </Row>
                    <div className="mx-sm-3 d-grid">
                      <button
                        className="btn btn-purple btn-block "
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {!isSubmitting ? (
                          <>Send</>
                        ) : (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </>
                        )}
                      </button>
                    </div>
                  </Form>
                </div>
              </Card>
              <div className="text-center mt-5">
                <label>
                  Go Back to &nbsp;
                  <span
                    className="link"
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Login{" "}
                  </span>
                </label>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;

import React, { createContext, useState } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../utils/auth/userPool";

const AccountContext = createContext();

const Account = (props) => {
  const [otpResult, setOtpResult] = useState("");
  const [allDevices, setAllDevices] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loader, setLoader] = useState(false);

  const [listItems, setListItems] = useState("");

  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  resolve(results);
                  localStorage.setItem(
                    "ezgeyser.userAttributes",
                    JSON.stringify(results)
                  );
                  setName(results.name);
                  setPhoneNumber(results.phone_number);
                }
              });
            });

            resolve({
              user,
              ...session,
              ...attributes,
            });
          }
        });
      } else {
        reject();
      }
      return;
    });

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });
      localStorage.setItem("ezgeyser.unconfirmedUser", authDetails.username);
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          const accessToken = data.getAccessToken();
          const JwtToken = data.getIdToken().getJwtToken();
          localStorage.setItem("ezgeyser.Authuser", accessToken.payload.sub);
          localStorage.setItem("ezgeyser.JwtToken", JwtToken);
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (data) => {
          resolve(data);
        },
      });
    });
  };

  const getUser = (email) => {
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool,
    });
  };

  const sendCode = (email) => {
    getUser(email).forgotPassword({
      onSuccess: (data) => {
        console.log("onSuccess:", data);
      },
      onFailure: (err) => {
        console.error("onFailure:", err);
      },
    });
  };

  const updateUserAttributes = async (attributeList) =>{
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
              user.updateAttributes(attributeList, function(err, result) {
                    if (err) {
                      // alert(err.message || JSON.stringify(err));
                      console.log("error" , err)
                      return;
                    }
                    console.log('call result: ' + result);
                  });
          }
        });
      } else {
        reject();
      }
      return;
    });
  }

  const reset_Password = (code, password) => {
    const userEmail = localStorage.getItem("ezgeyser.forgetPwdEmail");

    getUser(userEmail).confirmPassword(code, password, {
      onSuccess: (data) => {
        console.log("onSuccess:", data);
      },
      onFailure: (err) => {
        console.error("onFailure:", err);
      },
    });
  };


  const reSendOtp = (email) => {
    const Cognito_User = new CognitoUser({
      Username: email,
      Pool,
    });

    Cognito_User.resendConfirmationCode(function (error, result) {
      if (error) {
        return;
      }
      console.log("call result: " + result);
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        sendCode,
        reset_Password,
        otpResult,
        setOtpResult,
        reSendOtp,
        setAllDevices,
        allDevices,
        name,
        setListItems,
        listItems,
        setLoader,
        loader,
        setName,
        setPhoneNumber,
        phoneNumber,
        updateUserAttributes
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
export { Account, AccountContext };

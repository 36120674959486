import { useState } from "react";

const Index = (props) => {
  let { setTimer } = props;
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div className="d-flex align-items-center w-100">
        <select
          className="form-control w-100"
          onClick={(e) => {
            setShowMenu(!showMenu);
            setTimer(e.target.value);
          }}
        >
          <option>Select Time</option>
          <option value="30">30 Minutes</option>
          <option value="60">60 Minutes</option>
          <option value="90">90 Minutes</option>
          <option value="120">120 Minutes</option>
          <option value="150">150 Minutes</option>
          <option value="180">180 Minutes</option>
          <option value="210">210 Minutes</option>
          <option value="240">240 Minutes</option>
          <option value="270">270 Minutes</option>
          <option value="310">310 Minutes</option>
          <option value="330">330 Minutes</option>
          <option value="360">360 Minutes</option>
        </select>
      </div>
    </>
  );
};

export default Index;

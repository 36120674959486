import axiosInstance from "../../utils/axiosInstance";

import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import { AccountContext } from "../../session/userSession";
// import { GeyserService } from "@softoo-pvt-ltd/ezgeyser-device-sdk";
import Layout from "../../components/layout";
import Card from "../../components/card";
import Modal from "../../components/modal";
import List from "../../components/list";
import moment from "moment";
import Loader from "../../components/loader";

import { Row, Col } from "reactstrap";

const Index = (props) => {
  const { match } = props;
  const history = useHistory();
  const id = match.params.id;

  const { setListItems, listItems, setAllDevices } = useContext(AccountContext);
  const [startTime, setStartTime] = useState(moment());
  const [endTime, setEndTime] = useState(moment());

  const intialValues = {
    DeviceID: id,
    ScheduleID: "",
    title: "",
    startTime: "",
    endTime: "",
    temperature: "0",
    repeating: {
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
    },
  };

  const [schedule, setSchedule] = useState(intialValues);
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [timer, setTimer] = useState("");

  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteScheduleId, setDeleteScheduleId] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [delItemModal, setDelItemModal] = useState(false);
  const [inviteUserEmail, setInviteUserEmail] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [userList, setUserList] = useState([]);

  const [singleDeviceData, setSingleDeviceData] = useState("");

  let duplicateSchedule = { ...schedule };
  useEffect(() => {
    setListItems("");
    const user = localStorage.getItem("ezgeyser.Authuser");
    if (!user) {
      history.push("/");
    }
    getSchedules();
    checkUserStatus();
    // initGeyser();
  }, []);

  // const initGeyser = () => {
  //   const geyserService = new GeyserService({
  //     host: "a1so4q4tm7hwls-ats.iot.us-east-1.amazonaws.com",
  //     identityPoolId: "us-east-1:8bc42a5f-055e-48ba-b08f-865a61c03541",
  //     region: "us-east-1",
  //     userPoolId: "us-east-1_XX3Z3lbK1",
  //     userPoolWebClientId: "7er9p29qv8ea0bj4049us1j86",
  //   });

  //   geyserService.subscribeStatus({ deviceId: id }, (err, result) => {
  //     if (err) {
  //       console.log("Error occured");
  //       console.error(err);
  //       return;
  //     }

  //     console.log("Data");
  //     console.log(result);
  //     setSingleDeviceData(result);
  //   });
  // };
  const checkUserStatus = () => {
    axiosInstance.get(`/users/devices`).then((res) => {
      setAllDevices(res.data);
      const result = res.data.filter((item) => item.DeviceID === id);
      setUserStatus(result);
    });
    return;
  };

  const getSchedules = () => {
    axiosInstance.get(`/devices/schedules`).then((res) => {
      if (res) {
        const result = res.data.filter((item) => item.DeviceID === id);
        setListItems(result);
        return;
      }
    });
  };
  const confirmModal = () => {
    setFormErrors(validate(schedule));
  };
  const validate = (schedule) => {
    let errors = {};

    const start = `04/09/2013 ${moment(startTime).format("LT")}`;
    const end = `04/09/2013 ${moment(endTime).format("LT")}`;
    const diff = moment.duration(moment(end).diff(moment(start)));
    const minutes = parseInt(diff.asMinutes());


    schedule.startTime = moment(startTime).format("LT");
    schedule.endTime = moment(endTime).format("LT");

    if (!schedule.title) {
      errors.title = "Cannot be blank";
    }
    if (!schedule.startTime) {
      errors.startTime = "Cannot be blank";
    }
    if (schedule.endTime <= schedule.startTime) {
      errors.endTime = "Must be greater than Start Time";
    }
    if (!schedule.temperature) {
      errors.temperature = "Cannot be blank";
    } else if (schedule.temperature < 30) {
      errors.temperature = "Please Select Temperature 30 or more than 30";
    }
    if (
      Object.keys(schedule.repeating).every(
        (k) => schedule.repeating[k] === false
      )
    ) {
      errors.repeating = "Please select days first";
    }
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      axiosInstance
        .post("/devices/schedules", schedule)
        .then((res) => {
          if (res.status === 200) {
            setIsSubmitting(false);
            setSuccessMessage("Schedule Added Successfully");
            setTimeout(() => {
              setModal(!modal);
              setSuccessMessage("");
              const tempDevices = [...listItems];
              tempDevices.push(schedule);
              setListItems(tempDevices);
              duplicateSchedule.ScheduleID = "";
              duplicateSchedule.endTime = "";
              duplicateSchedule.startTime = "";
              duplicateSchedule.temperature = "";
              duplicateSchedule.title = "";
              duplicateSchedule.repeating.friday = false;
              duplicateSchedule.repeating.monday = false;
              duplicateSchedule.repeating.saturday = false;
              duplicateSchedule.repeating.thursday = false;
              duplicateSchedule.repeating.tuesday = false;
              duplicateSchedule.repeating.wednesday = false;
              duplicateSchedule.repeating.sunday = false;
              setSchedule({ ...duplicateSchedule });
              setFormErrors({});
            }, 1000);
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          setErrMessage(err.message);
          setTimeout(() => {
            setErrMessage("");
          }, 1000);
        });
    }
    return errors;
  };

  const deleteSchedule = () => {
    setIsSubmitting(true);
    axiosInstance
      .delete("/devices/schedules", {
        data: {
          DeviceID: deleteId,
          ScheduleID: deleteScheduleId,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setIsSubmitting(false);
          setSuccessMessage("Schedule Deleted Successfully");
          setTimeout(() => {
            setSuccessMessage("");
            if (deleteScheduleId !== "") {
              const result = listItems.filter(
                (item) => item.ScheduleID !== deleteScheduleId
              );
              const tempDevices = [...result];
              setListItems(tempDevices);
              setDelItemModal(!delItemModal);
              return true;
            }
          }, 1500);
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        setErrMessage(err.message);
        setTimeout(() => {
          setErrMessage("");
        }, 1500);
      });
  };

  const deleteItem = (title, message) => {
    setModalTitle(title);
    setModalMessage(message);
    setModal(!modal);
  };

  const handleModal = (title, message) => {
    getSchedules();
    if (listItems && listItems.length < 1) {
      duplicateSchedule.ScheduleID = (1).toString();
      setSchedule({ ...duplicateSchedule });
    }
    if (listItems && listItems.length !== 10) {
      const lastItem = listItems[listItems.length - 1];
      const idNo = parseInt(lastItem.ScheduleID);
      duplicateSchedule.ScheduleID = (idNo + 1).toString();
      setSchedule({ ...duplicateSchedule });
    } else {
      if (message === "Schedule") {
        setErrMessage("Can't add more schedule for this device");
      }
    }

    setModalTitle(title);
    setModalMessage(message);
    setModal(!modal);
  };

  const inviteUserHandler = () => {
    setFormErrors(validateShareEmail(inviteUserEmail));
  };

  const validateShareEmail = (inviteUserEmail) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!inviteUserEmail) {
      errors.inviteUserEmail = "Cannot be blank";
    } else if (!regex.test(inviteUserEmail)) {
      inviteUserEmail = "Invalid email format";
    }
    if (Object.keys(errors).length === 0) {

      setIsSubmitting(true);
      const data = {
        DeviceID: id,
        email: inviteUserEmail,
      };
      axiosInstance
        .post("/users/devices/share", data)
        .then((res) => {
          if (res.status === 200) {
            setIsSubmitting(false);
            setSuccessMessage("Device shared Successfully");
            const temp = [...userList];
            temp.push(data);
            setUserList(temp);
            setTimeout(() => {
              setSuccessMessage("");
            }, 1500);
          }
        })
        .catch((err) => {
          setErrMessage("Email not verified or Wrong email entered");
          setIsSubmitting(false);
          setTimeout(() => {
            setErrMessage("");
          }, 1500);
        });
    }
    return errors;
  };

  const deleteShareDevice = (email) => {
    const data = {
      DeviceID: id,
      email: email,
    };
    axiosInstance
      .post("/users/devices/share/delete", data)
      .then((res) => {
        if (res.status === 200) {
          setSuccessMessage("User Deleted Successfully");
          const usersList = [...userList];
          const index = usersList.findIndex((item) => item?.email === email);
          usersList.splice(index, 1);
          setUserList(usersList);
          setTimeout(() => {
            setSuccessMessage("");
          }, 1500);
        }
      })
      .catch((err) => {
        setErrMessage("User not deleted");
        setTimeout(() => {
          setErrMessage("");
        }, 1500);
      });
  };

  const cancelHandler = () => {
    setErrMessage("");
    setModal(!modal);
  };

  return (
    <>
      {/* {singleDeviceData ? ( */}
      <Layout>
        {modal && (
          <Modal
            confirmHandler={confirmModal}
            message={modalMessage}
            title={modalTitle}
            cancelHandler={cancelHandler}
            schedule={schedule}
            setSchedule={setSchedule}
            errMessage={errMessage}
            successMessage={successMessage}
            isSubmitting={isSubmitting}
            formErrors={formErrors}
            setTimer={setTimer}
            timer={timer}
            deviceID={id}
            setInviteUserEmail={setInviteUserEmail}
            inviteUserHandler={inviteUserHandler}
            setEndTime={setEndTime}
            endTime={endTime}
            setStartTime={setStartTime}
            startTime={startTime}
            setUserList={setUserList}
            userList={userList}
            deleteShareDevice={deleteShareDevice}
          />
        )}
        <div className="child-screen">
          <div className="justify-content-center">
            <Col md={12} lg={12} xl={12} className=" px-4">
              <Row>
                <div className="d-flex flex-wrap flex-column flex-lg-row flex-xl-row justify-content-between">
                  <div className="single-tile">
                    <Card
                      tileName={"Burner Status"}
                      tiledata={singleDeviceData.burnerStatus}
                      icon_bg={"bg-danger"}
                      icon={<i className="fa fa-fire icon-anim"></i>}
                    />
                  </div>
                  <div className="single-tile">
                    <Card
                      tileName={"Battery Percentage"}
                      tiledata={singleDeviceData.batteryPercentage}
                      icon_bg={"bg-info "}
                      icon={
                        <i
                          className="fa fa-battery-full icon-anim"
                          aria-hidden="true"
                        ></i>
                      }
                    />
                  </div>
                  <div className="single-tile">
                    <Card
                      tileName={"Operating Status"}
                      tiledata={singleDeviceData.operatingStatus}
                    />
                  </div>
                  <div className="single-tile">
                    <Card
                      tileName={"Gas Pressure"}
                      tiledata={singleDeviceData.gasPressure}
                    />
                  </div>
                  <div className="single-tile">
                    <Card
                      tileName={"Current Temperature"}
                      tiledata={singleDeviceData.currentTemperature}
                      icon_bg={"bg-primary"}
                      icon={
                        <i
                          className="fa fa-thermometer-three-quarters icon-anim"
                          aria-hidden="true"
                        ></i>
                      }
                    />
                  </div>
                  <div className="single-tile">
                    <Card
                      tileName={"Default Temperature"}
                      tiledata={singleDeviceData.defaultTemperature}
                      icon_bg={"bg-success"}
                      icon={
                        <i
                          className="fa fa-thermometer-three-quarters icon-anim"
                          aria-hidden="true"
                        ></i>
                      }
                    />
                  </div>
                  <div className="single-tile">
                    <Card
                      tileName={"Error Code"}
                      tiledata={singleDeviceData.errorCode}
                      icon_bg={"bg-danger"}
                      icon={
                        <i
                          className="fa fa-exclamation icon-anim"
                          aria-hidden="true"
                        ></i>
                      }
                    />
                  </div>
                  <div className="single-tile">
                    <Card
                      tileName={"Pilot Status"}
                      tiledata={singleDeviceData.pilotStatus}
                      icon_bg={"bg-warning"}
                      icon={
                        <i
                          className="fa fa-sun icon-anim"
                          aria-hidden="true"
                        ></i>
                      }
                    />
                  </div>
                  <div className="single-tile">
                    <Card
                      tileName={"valve Status"}
                      tiledata={singleDeviceData.valveStatus}
                      icon_bg={"bg-info"}
                      icon={<i className="fas fa-water icon-anim"></i>}
                    />
                  </div>
                  <div className="single-tile">
                    <Card
                      tileTitle={"name"}
                      tileName={"Device Schedule"}
                      icon_bg={"bg-dark"}
                      icon={
                        <i
                          className="fa fa-clock icon-anim"
                          aria-hidden="true"
                        ></i>
                      }
                    />
                  </div>
                  <div className="single-tile"></div>
                  <div className="single-tile"></div>
                </div>
              </Row>
              <div className="pt-4 d-flex justify-content-end px-4">
                {userStatus && userStatus[0].master === 1 ? (
                  <>
                    <button
                      className="btn btn-purple btn-block mx-2"
                      onClick={() => {
                        handleModal("Share device", "Share This Device");
                      }}
                    >
                      Share Device
                    </button>
                  </>
                ) : (
                  ""
                )}

                <button
                  className="btn btn-purple btn-block "
                  onClick={() => handleModal("Add New Timer", "Add timer")}
                >
                  Add Timer
                </button>
                <button
                  className="btn btn-purple btn-block mx-2"
                  onClick={() => handleModal("Add New Schedule", "Schedule")}
                >
                  Add Schedule
                </button>
              </div>
              <div className="pt-5 pb-5">
                <List
                  viewAction={() => {
                    console.log("this is listed");
                  }}
                  listHeading={"Device Schedule"}
                  colOneHeading={"Title"}
                  colTwoHeading={"Temperature"}
                  colThreeHeading={"Off/Repeat"}
                  colFourHeading={"Actions"}
                  placeholder={"Search"}
                  deleteId={deleteId}
                  setDeleteId={setDeleteId}
                  deleteSchedule={deleteSchedule}
                  setDeleteScheduleId={setDeleteScheduleId}
                  deleteItem={deleteItem}
                  deleteScheduleId={deleteScheduleId}
                  setDelItemModal={setDelItemModal}
                  delItemModal={delItemModal}
                  errMessage={errMessage}
                  successMessage={successMessage}
                  isSubmitting={isSubmitting}
                />
              </div>
            </Col>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default withRouter(Index);

import { useState } from "react";
import UserPool from "../../utils/auth/userPool";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Input,
  Label,
  Form,
} from "reactstrap";

// import images
import profileImg from "../../assets/images/profile-img.png";

const Register = (props) => {
  const { history } = props;
  let intialValues = {
    fullName: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const [userData, setUserData] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  let duplicateUserData = { ...userData };

  const Submit = (e) => {
    e.preventDefault();

    setFormErrors(validate(userData));
  };
  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const Number = /^\+(?:[0-9]●?){6,14}[0-9]$/g;

    if (!values.fullName) {
      errors.fullName = "Cannot be blank";
      setIsSubmitting(false);
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "Cannot be blank";
      setIsSubmitting(false);
    } else if (!Number.test(values.phoneNumber)) {
      errors.phoneNumber = "Invalid Number format";
      setIsSubmitting(false);
    }

    if (!values.email) {
      errors.email = "Cannot be blank";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid email format";
      setIsSubmitting(false);
    }

    if (!values.password) {
      errors.password = "Cannot be blank";
    } else if (values.password.length < 6) {
      errors.password = "Password must be 6 characters";
      setIsSubmitting(false);
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Cannot be blank";
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = "You Entered Wrong Password";
      setIsSubmitting(false);
    }
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      UserPool.signUp(
        userData.email,
        userData.password,
        [
          { Name: "name", Value: userData.fullName },
          { Name: "phone_number", Value: userData.phoneNumber },
        ],
        null,
        (err, result) => {
          if (err) {
            setErrMessage(err.message);
            setTimeout(() => {
              setErrMessage("");
            }, 3000);
          } else {
            setSuccessMessage("User Registered succesfully");
            var cognitoUser = result.user;
            localStorage.setItem(
              "ezgeyser.unconfirmedUser",
              cognitoUser.getUsername()
            );
            setTimeout(() => {
              history.push("/confirm-user");
            }, 5000);
          }
          setIsSubmitting(false);
        }
      );
    }

    return errors;
  };

  return (
    <>
      <div className="account-pages min-vh-100">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={8} lg={6} xl={5} className="mt-2">
              <Card className="overflow-hidden">
                <div className="bg-purple bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-white p-4">
                        <h5>Free Register</h5>
                        <p>Get your free account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-2">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        Submit(e);
                      }}
                    >
                      {successMessage && (
                        <div className="justify-content-between d-flex  align-items-center alert-succes border-success rounded mx-5 p-2">
                          <span className="text-success text-center">
                            {successMessage}
                          </span>
                          <i
                            className="fa fa-times pt-1 text-success"
                            aria-hidden="true"
                            onClick={() => {
                              setSuccessMessage("");
                            }}
                          ></i>
                        </div>
                      )}
                      {errMessage && (
                        <div className="justify-content-between align-items-center alert-fail border-success rounded mx-5 d-flex p-2">
                          <span className="text-danger text-center">
                            {errMessage}
                          </span>
                          <i
                            className="fa fa-times pt-1 text-danger"
                            aria-hidden="true"
                            onClick={() => {
                              setErrMessage("");
                            }}
                          ></i>
                        </div>
                      )}
                      <div className="col mb-2">
                        <Label className="form-label mb-1">Full Name</Label>
                        <Input
                          className={
                            formErrors.fullName
                              ? "border border-danger form-control input-style "
                              : " form-control input-style"
                          }
                          placeholder="Full Name"
                          type="text"
                          maxLength="50"
                          autoComplete="off"
                          onChange={(e) => {
                            duplicateUserData.fullName = e.target.value;
                            setUserData({ ...duplicateUserData });
                          }}
                        />
                        {formErrors.fullName && (
                          <span className="error text-danger ">
                            {formErrors.fullName}
                          </span>
                        )}
                      </div>
                      <div className="mb-2">
                        <Label className="form-label mb-1">Email</Label>
                        <Input
                          className={
                            formErrors.phoneNumber
                              ? "border border-danger form-control input-style"
                              : " form-control input-style"
                          }
                          placeholder="Email"
                          maxLength="50"
                          type="text"
                          autoComplete="off"
                          onChange={(e) => {
                            duplicateUserData.email = e.target.value;
                            setUserData({ ...duplicateUserData });
                          }}
                        />
                        {formErrors.email && (
                          <span className="error text-danger">
                            {formErrors.email}
                          </span>
                        )}
                      </div>
                      <div className="mb-2">
                        <Label className="form-label mb-1">Phone Number</Label>
                        <Input
                          className={
                            formErrors.phoneNumber
                              ? "border border-danger form-control input-style"
                              : " form-control input-style"
                          }
                          placeholder="Phone Number e.g +923..."
                          type="text"
                          maxLength="15"
                          autoComplete="off"
                          onChange={(e) => {
                            duplicateUserData.phoneNumber = e.target.value;
                            setUserData({ ...duplicateUserData });
                          }}
                        />
                        {formErrors.phoneNumber && (
                          <span className="error text-danger">
                            {formErrors.phoneNumber}
                          </span>
                        )}
                      </div>

                      <div className="mb-2">
                        <Label className="form-label mb-1">Password</Label>
                        <div className="d-flex align-items-center">
                          <Input
                            className={
                              formErrors.password
                                ? "border border-danger form-control input-style position-relative"
                                : " form-control input-style position-relative"
                            }
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            maxLength="10"
                            autoComplete="off"
                            onChange={(e) => {
                              duplicateUserData.password = e.target.value;
                              setUserData({ ...duplicateUserData });
                            }}
                          />
                          <div className="input-group-addon eye-password">
                            <i
                              className={`fa  ${
                                showPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              aria-hidden="true"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            ></i>
                          </div>
                        </div>
                        {formErrors.password && (
                          <span className="error text-danger">
                            {formErrors.password}
                          </span>
                        )}
                      </div>
                      <div className="mb-4">
                        <Label className="form-label mb-1">
                          Confirm Password
                        </Label>
                        <div className="d-flex align-items-center">
                          <Input
                            className={
                              formErrors.confirmPassword
                                ? "border border-danger form-control input-style position-realtive"
                                : " form-control input-style position-relative"
                            }
                            placeholder="Confirm Password"
                            type={showConfirmPassword ? "text" : "password"}
                            maxLength="10"
                            autoComplete="off"
                            onChange={(e) => {
                              duplicateUserData.confirmPassword =
                                e.target.value;
                              setUserData({ ...duplicateUserData });
                            }}
                          />
                          <div className="input-group-addon eye-password">
                            <i
                              className={`fa  ${
                                showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              aria-hidden="true"
                              onClick={() => {
                                setShowConfirmPassword(!showConfirmPassword);
                              }}
                            ></i>
                          </div>
                        </div>
                        {formErrors.confirmPassword && (
                          <span className="error text-danger">
                            {formErrors.confirmPassword}
                          </span>
                        )}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-purple btn-block "
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {!isSubmitting ? (
                            <>Create Account</>
                          ) : (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Loading...
                            </>
                          )}
                        </button>
                      </div>
                      <div className="mt-4 mb-4 text-center">
                        <label>By registering you agree to the Egyzer </label>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2 mb-2 text-center">
                <label>
                  Already have an account ? &nbsp;
                  <span
                    className="link"
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Sign in
                  </span>
                </label>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Register;

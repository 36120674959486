import React, { useState, useEffect } from "react";
import SideBar from "../sidebar";
import TopBar from "../top-bar";
import classNames from "classnames";
import { Container } from "reactstrap";
import Modal from "../modal";

const Index = (props) => {
  const [showNav, setShowNav] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [profile, setProfile] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const resize = () => {
    let currentHideNav = window.innerWidth <= 760;
    if (currentHideNav) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  };

  const handleModal = (title, message) => {
    setModalTitle(title);
    setModalMessage(message);
    setModal(!modal);
  };

  return (
    <>
      <Container
        fluid
        className={classNames(
          "content d-flex overflow-auto position-relative",
          {
            "is-open": showNav,
          }
        )}
      >
        {modal && (
          <Modal
            confirmHandler={handleModal}
            message={modalMessage}
            title={modalTitle}
            cancelHandler={handleModal}
          />
        )}
        <div
          className={showNav ? " left-side sidebar is-open " : " left-close"}
        >
          <SideBar showNav={showNav} setShowNav={setShowNav} />
        </div>

        <div className=" w-100 position-relative">
          {/* <div className="position-fixed bottom-0 end-0 py-1 px-1 mx-4 my-4">
            {showSettings && (
              <div className="mb-3 anim">
                <span
                  className="float-item  mb-3 rounded-circle d-flex justify-content-center align-items-center"
                  onClick={() => {
                    handleModal("Title","Do you want to reset your Current Settings ?");
                  }}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i>
                </span>
                <span className="float-item mb-3 rounded-circle d-flex justify-content-center align-items-center"
                onClick={() => {
                  handleModal("Users List", "userList");
                }}>
                  <i className="fa fa-users" aria-hidden="true"></i>
                </span>
                <span className="float-item  rounded-circle d-flex justify-content-center align-items-center">
                  <i className="fa fa-user-plus" aria-hidden="true"></i>
                </span>
              </div>
            )}
            <div>
              <span
                className={`float-item rounded-circle d-flex justify-content-center align-items-center  ${
                  showSettings ? "float-item-active" : ""
                }`}
                onClick={() => {
                  setShowSettings(!showSettings);
                }}
              >
                <i
                  className={`fa fa-cog ${
                    showSettings ? "fa-active " : "fa-unactive"
                  }`}
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          </div> */}
          <TopBar
            setShowNav={setShowNav}
            showNav={showNav}
            setProfile={setProfile}
            profile={profile}
          />
          <div className="screen-divider"></div>
          {props.children}
        </div>
      </Container>
    </>
  );
};

export default Index;

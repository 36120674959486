import { useState, useContext, useEffect } from "react";
// import Input from "../../components/inputfield";

import { Row, Col, Card, Container, Form, Input } from "reactstrap";
import { Label } from "reactstrap";

// import images
import profileImg from "../../assets/images/profile-img.png";
import { AccountContext } from "../../session/userSession";

const Index = (props) => {
  let { history } = props;

  const intialValues = { code: "", password: "", confirmpassword: "" };

  const [resetPassword, setResetPassword] = useState({ intialValues });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  let duplicateresetPassword = { ...resetPassword };
  const { reset_Password } = useContext(AccountContext);

  useEffect(() => {
    const userEmail = localStorage.getItem("ezgeyser.forgetPwdEmail");
    if (!userEmail) {
      history.push("/forget-password");
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validate(resetPassword));
  };

  const validate = (resetPassword) => {
    let errors = {};

    if (resetPassword.password !== resetPassword.confirmpassword) {
      errors.notsame = "Password is not same";
    }
    if (!resetPassword.code) {
      errors.code = "Cannot be blank";
    } else if (resetPassword.code.length < 4) {
      errors.code = "Password must be 6 characters";
    }

    if (!resetPassword.password) {
      errors.password = "Cannot be blank";
    } else if (resetPassword.password.length < 4) {
      errors.password = "Password must be 6 characters";
    }

    if (!resetPassword.confirmpassword) {
      errors.confirmpassword = "Cannot be blank";
    } else if (resetPassword.confirmpassword.length < 4) {
      errors.confirmpassword = "Password must be 6 characters";
    }

    if (Object.keys(errors).length === 0) {
      reset_Password(resetPassword.code, resetPassword.password);
      history.push("/");
    }
    return errors;
  };

  return (
    <>
      <div className="account-pages min-vh-100 d-flex justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={7} lg={5} xl={5}>
              <Card className="overflow-hidden mt-4">
                <div className="bg-purple">
                  <Row>
                    <Col className="col-7">
                      <div className="text-white p-4">
                        <h5>Forget password!</h5>
                        <p>Reset with code.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <div className="px-2 py-4">
                  <Form className="form-inline" onSubmit={submitHandler}>
                    <Row className="justify-content-center d-flex mb-3">
                      <div className=" mx-sm-3 col">
                        <p className="form-label mb-2">
                          Enter code received in your email
                        </p>
                        <div>
                          <Label className="form-label mb-1">Enter Code:</Label>
                        </div>
                        <div className="mb-2">
                          <Input
                            width={"w-100"}
                            className={
                              formErrors.code
                                ? "border border-danger w-100 form-control input-style"
                                : " form-control w-100 input-style"
                            }
                            placeholder={"Enter Code"}
                            onChange={(e) => {
                              e.target.value = e.target.value.slice(0, 6);
                              duplicateresetPassword.code = e.target.value;
                              setResetPassword({ ...duplicateresetPassword });
                            }}
                            type={"text"}
                          />
                          {formErrors.code && (
                            <span className="error text-danger">
                              {formErrors.code}
                            </span>
                          )}
                        </div>
                        <Label className="form-label mb-1">
                          Enter Password:
                        </Label>
                        <div className="mb-2">
                          <Input
                            width={"w-100"}
                            className={
                              formErrors.password
                                ? "border border-danger w-100 form-control input-style"
                                : " form-control w-100 input-style"
                            }
                            placeholder={"Enter Password"}
                            type={"password"}
                            maxLength="10"
                            onChange={(e) => {
                              duplicateresetPassword.password = e.target.value;
                              setResetPassword({ ...duplicateresetPassword });
                            }}
                          />
                          {formErrors.password && (
                            <span className="error text-danger">
                              {formErrors.password}
                            </span>
                          )}
                        </div>
                        <Label className="form-label mb-1">
                          Confirm Password:
                        </Label>
                        <div>
                          <Input
                            width={"w-100"}
                            maxLength="10"
                            className={
                              formErrors.confirmpassword
                                ? "border border-danger w-100 form-control input-style"
                                : " form-control w-100 input-style"
                            }
                            placeholder={"Enter Password"}
                            onChange={(e) => {
                              duplicateresetPassword.confirmpassword =
                                e.target.value;
                              setResetPassword({ ...duplicateresetPassword });
                            }}
                            type={"password"}
                          />
                          {formErrors.confirmpassword && (
                            <span className="error text-danger ">
                              {formErrors.confirmpassword}
                            </span>
                          )}
                          {!formErrors.confirmpassword &&
                            formErrors.notsame && (
                              <span className="error text-danger ">
                                {formErrors.notsame}
                              </span>
                            )}
                        </div>
                      </div>
                    </Row>
                    <div className="mx-sm-3 col">
                      <button
                        className="btn btn-purple btn-block "
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                  </Form>
                </div>
              </Card>
              <div className="text-center mt-5">
                <label>
                  Go Back to &nbsp;
                  <span
                    className="link"
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Login{" "}
                  </span>
                </label>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;

import { useState, useContext, useEffect } from "react";
import { AccountContext } from "../../session/userSession";

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Input,
  Label,
  Form,
} from "reactstrap";

import profileImg from "../../assets/images/ezGeyser_Horizontal transparent.png";

const Login = (props) => {
  let { history } = props;

  const intialValues = { email: "", password: "" };

  const [loginData, setLoginData] = useState({ intialValues });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  let duplicateLoginData = { ...loginData };


  const { authenticate } = useContext(AccountContext);

  useEffect(() => {
    const Authuser = localStorage.getItem("ezgeyser.JwtToken");
    if (Authuser) {
      history.push("/devices");
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validate(loginData));
  };

  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Cannot be blank";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid email format";
    }

    if (!values.password) {
      errors.password = "Cannot be blank";
    } else if (values.password.length < 4) {
      errors.password = "Password must be 6 characters";
    }

    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      authenticate(loginData.email, loginData.password)
        .then((data) => {
          setIsSubmitting(false);
          history.push("/devices");
        })
        .catch((err) => {
          setErrMessage(err.message);
          if (err.message === "User is not confirmed.") {
            setTimeout(() => {
              setErrMessage("");
              history.push("/confirm-user");
            }, 1500);
          } else {
            setIsSubmitting(false);
            setTimeout(() => {
              setErrMessage("");
            }, 1500);
          }
        });
    }
    return errors;
  };

  return (
    <>
      <div className="account-pages min-vh-100 d-flex justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={7} lg={5} xl={5}>
              <Card className="overflow-hidden mt-4">
                <div className="bg-purple">
                  <Row className="flex-column justify-content-center align-items-center">
                    <div className="col-5 px-3 py-4">
                      <img src={profileImg} alt="" className="w-100" />
                    </div>
                  </Row>
                </div>
                <CardBody className="pt-2 input-card">
                  {errMessage && (
                    <Row className="justify-content-center alert-fail border-success rounded mx-5 d-flex p-2">
                      <span className="text-danger text-center">
                        {errMessage}
                      </span>
                    </Row>
                  )}
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        submitHandler(e);
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label mb-1">Email</Label>
                        <Input
                          className={
                            errMessage || formErrors.email
                              ? "border border-danger form-control input-style"
                              : " form-control input-style"
                          }
                          placeholder="Enter Email"
                          maxLength="50"
                          type="email"
                          autoComplete="off"
                          onChange={(e) => {
                            duplicateLoginData.email = e.target.value;
                            setLoginData({ ...duplicateLoginData });
                          }}
                        />
                        {formErrors.email && (
                          <span className="error text-danger ">
                            {formErrors.email}
                          </span>
                        )}
                      </div>
                      <div className="mb-1">
                        <Label className="form-label mb-1">Password</Label>
                        <div className="d-flex align-items-center">
                          <Input
                            className={
                              formErrors.password
                                ? "border border-danger form-control input-style position-relative"
                                : " form-control input-style position-relative"
                            }
                            type={showPassword ? "text" : "password"}
                            maxLength="10"
                            placeholder="Enter Password"
                            autoComplete="off"
                            onChange={(e) => {
                              duplicateLoginData.password = e.target.value;
                              setLoginData({ ...duplicateLoginData });
                            }}
                          />
                          <div className="input-group-addon eye-password">
                            <i
                              className={`fa  ${
                                showPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              aria-hidden="true"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            ></i>
                          </div>
                        </div>
                        {formErrors.password && (
                          <span className="error text-danger">
                            {formErrors.password}
                          </span>
                        )}
                      </div>
                      <div className="m-0 p-0 d-flex justify-content-end">
                        <label
                          className="link"
                          onClick={() => {
                            history.push("/forget-password");
                          }}
                        >
                          Forget Password ?
                        </label>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-purple btn-block "
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {!isSubmitting ? (
                            <>Log In</>
                          ) : (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Loading...
                            </>
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="text-center mt-5">
                <label>
                  Not Registered Yet ? &nbsp;
                  <span
                    className="link"
                    onClick={() => {
                      history.push("/sign-up");
                    }}
                  >
                    Sign Up{" "}
                  </span>
                </label>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;

import { BrowserRouter as Router, Switch, Route  } from "react-router-dom";

import Login from "../pages/login";
import Register from "../pages/register";
import ForgetPassword from "../pages/forgetPassword";
import Devices from "../pages/devices";
import SendOtp from "../pages/confirmOtp";
import ResetPassword from "../pages/forgetPassword/resetPwd";
import DeviceData from '../pages/deviceData'


const Index = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" component={Login} exact />
          <Route path="/sign-up" component={Register} />
          <Route path="/forget-password" component={ForgetPassword} />
          <Route exact path="/devices" component={Devices} />
          <Route exact path="/confirm-user" component={SendOtp} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/device-data/:id" component={DeviceData} />
        </Switch>
      </Router>
    </>
  );
};

export default Index;

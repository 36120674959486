
const Index = (props) => {
  let { tiledata, tileName , icon , icon_bg } = props;
  return (
    <div>
      <div className="tiles bg-white rounded border py-4 d-flex justify-content-between px-3 mb-3">
        <div className="p-0 m-0 d-flex flex-column">
          <div className=" m-0 rounded tile-name">{tileName}</div>
          <div className=" tile-title">
            {tiledata}
          </div>
        </div>
        <div className="card-icon d-flex align-items-center">
          <span className={`rounded-circle d-flex align-items-center justify-content-center  ${ icon_bg}` }>
            {icon ? icon : 
            <> <i className="fa fa-plus" aria-hidden="true"></i>
            </>}  
          </span>
          </div>
      </div>
    </div>
  );
};

export default Index;


import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";

const Index = ({time , setTime}) => {

  const handleValueChange = (value) => {
    setTime(value);
  };
  return (
    <div>
      <TimePicker
        value={time}
        onChange={handleValueChange}
        showSecond={false}
        minuteStep={5}
        use12Hours
        className="form-control"
      />
    </div>
  );
};

export default Index;

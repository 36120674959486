import { Input, Label } from "reactstrap";

const Index = (props) => {
  let { text, label, className, placeholder, onChange, type, value } = props;
  return (
    <>
      <div className="col">
        <p>{text}</p>
        <Label className="form-label mb-0">{label}</Label>
        <div>
          <Input
            value={value}
            className={className}
            placeholder={placeholder ? placeholder : "Enter Email"}
            type={type}
            autoComplete="off"
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
};

export default Index;

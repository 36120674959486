import "./checkBox.css";
const Index = (props) => {
  let { schedule , setSchedule } = props;
  // let weekdays = {...schedule}
  return (
    <>
      <div
        className="weekDays-selector"
        onChange={(e) => {
          const weekdays = { ...schedule };
          switch (e.target.value) {
            case "sunday": {
              weekdays.repeating.sunday = !weekdays.repeating.sunday;
              break;
            }
            case "monday": {
              weekdays.repeating.monday = !weekdays.repeating.monday;
              break;
            }
            case "tuesday": {
              weekdays.repeating.tuesday = !weekdays.repeating.tuesday;
              break;
            }
            case "wednesday": {
              weekdays.repeating.wednesday = !weekdays.repeating.wednesday;
              break;
            }
            case "thursday": {
              weekdays.repeating.thursday = !weekdays.repeating.thursday;
              break;
            }
            case "friday": {
              weekdays.repeating.friday = !weekdays.repeating.friday;
              break;
            }
            case "saturday": {
              weekdays.repeating.saturday = !weekdays.repeating.saturday;
              break;
            }
            default:
              break;
          }
          setSchedule(weekdays)
        }}
      >
        <input
          type="checkbox"
          value="sunday"
          id="weekday-sun"
          className="weekday"
        />
        <label htmlFor="weekday-sun">S</label>
        <input
          type="checkbox"
          value="monday"
          id="weekday-mon"
          className="weekday"
        />
        <label htmlFor="weekday-mon">M</label>
        <input
          type="checkbox"
          value="tuesday"
          id="weekday-tue"
          className="weekday"
        />
        <label htmlFor="weekday-tue">T</label>
        <input
          type="checkbox"
          value="wednesday"
          id="weekday-wed"
          className="weekday"
        />
        <label htmlFor="weekday-wed">W</label>
        <input
          type="checkbox"
          value="thursday"
          id="weekday-thu"
          className="weekday"
        />
        <label htmlFor="weekday-thu">T</label>
        <input
          type="checkbox"
          value="friday"
          id="weekday-fri"
          className="weekday"
        />
        <label htmlFor="weekday-fri">F</label>
        <input
          type="checkbox"
          value="saturday"
          id="weekday-sat"
          className="weekday"
        />
        <label htmlFor="weekday-sat">S</label>
      </div>
    </>
  );
};

export default Index;

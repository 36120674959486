import { useState, useContext } from "react";
import { AccountContext } from "../../session/userSession";

import axiosInstance from "../../utils/axiosInstance";

import Card from "../../components/card";
import Modal from "../../components/modal";
import { Col, Row } from "reactstrap";

const Tiles = () => {

  const { allDevices, setListItems, listItems } = useContext(AccountContext);

  const intialValues = {
    macAddress: "",
    deviceName: "",
    defaultTemperature: "",
  };

  const [newDevice, setNewDevice] = useState(intialValues);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  let duplicateNewDevice = {...newDevice}

  const handleModal = (title, message) => {
    setModalTitle(title);
    setModalMessage(message);
    setModal(!modal);
  };

  const sendDeviceData = (e) => {
    setFormErrors(validate(newDevice));
  };

  const validate = (newDevice) => {
    let errors = {};

    if (!newDevice.macAddress) {
      errors.macAddress = "Cannot be blank";
    }
    if (!newDevice.deviceName) {
      errors.deviceName = "Cannot be blank";
    }
    if (!newDevice.defaultTemperature) {
      errors.defaultTemperature = "Cannot be blank";
    } else {
      setIsSubmitting(true);
      let data = {
        DeviceID: newDevice.macAddress,
        DeviceName: newDevice.deviceName,
        DefaultTemprature: newDevice.defaultTemperature,
        HolidayMode: false,
      };
      axiosInstance
        .post("/users/devices", data)
        .then((res) => {
          if (res.status === 200) {
            setIsSubmitting(false);
            setSuccessMessage("Device Added Successfully");
            setTimeout(() => {
              setSuccessMessage("");
              const tempDevices = [...listItems];
              tempDevices.push(data);
              setListItems(tempDevices);
              duplicateNewDevice.macAddress = "";
              duplicateNewDevice.deviceName = "";
              duplicateNewDevice.defaultTemperature="";
              setNewDevice({...duplicateNewDevice})
              setModal(!modal);
            }, 1000);
          }
        })
        .catch((err) => {
          if (err === 500) {
            setErrMessage("network error");
            return;
          }
          setIsSubmitting(false);
          setErrMessage(err.message);
          setTimeout(() => {
            setErrMessage("");
          }, 1000);
        });
    }
    return errors;
  };


  return (
    <>
      {modal && (
        <Modal
          confirmHandler={sendDeviceData}
          message={modalMessage}
          title={modalTitle}
          cancelHandler={handleModal}
          newDevice={newDevice}
          setNewDevice={setNewDevice}
          formErrors={formErrors}
          isSubmitting={isSubmitting}
          errMessage={errMessage}
          successMessage={successMessage}
        />
      )}
      <Col md={12} lg={12} xl={12} className=" px-4">
        <Row>
          <div className="d-flex flex-wrap flex-column flex-lg-row flex-xl-row justify-content-between">
            <div className="single-tile">
              <Card
                tileName={"Total Devices"}
                icon_bg={"bg-warning"}
                tiledata={allDevices.length}
              />
            </div>
            <div className="single-tile">
              <Card
                tileName={"Active Devices"}
                tiledata={0}
                icon_bg={"bg-success"}
                icon={<i className="fa fa-check" aria-hidden="true"></i>}
              />
            </div>
            <div className="single-tile">
              <Card
                tileName={"InActive Devices"}
                tiledata={0}
                icon_bg={"bg-danger"}
                icon={<i className="fa fa-times" aria-hidden="true"></i>}
              />
            </div>
            <div
              className="single-tile"
              onClick={() => {
                handleModal("Add New Device", "Add Device");
              }}
            >
              <Card tiledata={"Device"} tileName={"Add New"} />
            </div>
          </div>
        </Row>
      </Col>
    </>
  );
};

export default Tiles;

import { useEffect } from "react";
import { useHistory } from "react-router";
import Pool from "../../utils/auth/userPool";

import { NavLink } from "react-router-dom";

function Index(props) {
  let history = useHistory();
  let { showNav } = props;

  useEffect(() => {
    const user = localStorage.getItem("ezgeyser.Authuser");
    if (!user) {
      history.push("/");
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("ezgeyser.JwtToken");
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      history.push("/");
    }
  };

  return (
    <>
      <div
        className={
          showNav ? "sidebar is-open w-100 position-fixed " : " sidebar w-100 "
        }
      >
        <div className="d-flex justify-content-center py-4">EzG</div>
        <ul className="list-unstyled pt-1 d-flex flex-column align-items-center justify-content-center">
          <li>
            <NavLink
              to="/devices"
              className="d-flex align-items-center justify-content-between py-4"
            >
              <span className="side-bar-item rounded-circle d-flex justify-content-center align-items-center">
                <i className="fa fa-home" aria-hidden="true"></i>
              </span>
            </NavLink>
          </li>
          <li className="">
            <span
              className="side-bar-item rounded-circle d-flex justify-content-center align-items-center"
              onClick={() => {
                logout();
              }}
            >
              <i className="fa fa-sign-out-alt"></i>
            </span>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Index;

import { useState, useContext } from "react";
import Toggle from "../toggleButton/index";
import Modal from "../modal";
import Loader from "../loader";

import { AccountContext } from "../../session/userSession";

const Index = (props) => {
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const { listItems } = useContext(AccountContext);

  let {
    viewAction,
    listHeading,
    colOneHeading,
    colTwoHeading,
    colThreeHeading,
    colFourHeading,
    placeholder,
    deleteId,
    setDeleteId,
    deleteScheduleId,
    setDeleteScheduleId,
    deleteItem,
    deleteSchedule,
    setDelItemModal,
    delItemModal,
    errMessage,
    successMessage,
    isSubmitting,
  } = props;

  const cancelHandler = () => {
    setDelItemModal(!delItemModal);
  };

  const Delete = (title, message, id) => {
    setModalTitle(title);
    setModalMessage(message);
    setDelItemModal(!delItemModal);
  };

  return (
    <>
      {delItemModal && (
        <Modal
          confirmHandler={deleteSchedule}
          message={modalMessage}
          title={modalTitle}
          itemId={deleteId}
          cancelHandler={cancelHandler}
          deleteScheduleId={deleteScheduleId}
          errMessage={errMessage}
          successMessage={successMessage}
          isSubmitting={isSubmitting}
        />
      )}

      <div className="bg-light py-4 px-4 rounded border run-dev-container table-responsive">
        <div className="section-header text-center pb-3 h4 ">{listHeading}</div>
        <div className="d-flex justify-content-end">
          <div className="d-flex justify-content-between bg-white search-bar rounded-pill">
            <div className="form-outline">
              <input
                type="text"
                placeholder={placeholder}
                className="form-control shadow-none py-2 input-style border-0 rounded-pill"
              />
            </div>
            <span className="d-flex align-items-center text-secondary">
              <i className="fa fa-search"></i>
            </span>
          </div>
        </div>
        {listItems ? (
          <table className="table table-striped ">
            <thead>
              <tr>
                <th>{colOneHeading}</th>
                <th>{colTwoHeading}</th>
                <th>{colThreeHeading}</th>
                <th>{colFourHeading}</th>
              </tr>
            </thead>
            <tbody>
              <>
                {listItems.map((items, index) => (
                  <tr key={index}>
                    <td className="pt-3">
                      {items?.DeviceName}
                      {items?.title}
                    </td>
                    <td className="pt-3">
                      {items.temperature ? `${items.temperature}` : 20}
                      &#8451;
                    </td>
                    <td>
                      <Toggle />
                    </td>
                    <td className="pt-3">
                      <i
                        className="fa fa-eye px-2"
                        aria-hidden="true"
                        onClick={() => {
                          viewAction(items.DeviceID);
                        }}
                      ></i>
                      <i
                        className="fa fa-trash"
                        aria-hidden="true"
                        onClick={() => {
                          if (items && items.ScheduleID) {
                            Delete(
                              "Delete",
                              "Deleteitem",
                              items.DeviceID,
                              items.ScheduleID
                            );
                            setDeleteId(items.DeviceID);
                            setDeleteScheduleId(items.ScheduleID);
                          } else {
                            deleteItem(
                              "Delete Item",
                              "Deleteitem",
                              items.DeviceID
                            );
                            setDeleteId(items.DeviceID);
                          }
                        }}
                      ></i>
                    </td>
                  </tr>
                ))}
              </>
            </tbody>
          </table>
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
    </>
  );
};

export default Index;

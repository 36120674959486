import axios from "axios";

import { getFirstValidationError } from "./getFirstValidationError";
import { defaultConfig } from "./defaultConfig";
const requestHandler = (config) => {

  const token = localStorage.getItem("ezgeyser.JwtToken");

  config.headers = {
    ...config.headers,
    authorization: token,
    "Content-Type": `application/json`,
  };

  return config;
};

const axiosInstance = axios.create({
  defaultConfig,
  baseURL: `https://api.ezgeyser.softoo-dev.com`,
});
axiosInstance.defaults.timeout = 30000;

axiosInstance.interceptors.request.use(
  function (config) {
    return requestHandler(config);
  },
  function (error) {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(getFirstValidationError(error));
  }
);

export default axiosInstance;

import Routes from "./Routes/index";
import { Account } from "./session/userSession";
function App() {
  return (
    <>
      <Account>
        <Routes />
      </Account>
    </>
  );
}

export default App;

import { useState, useContext } from "react";
import Input from "../../components/inputfield";

import { Row, Col, Card, Container, Form } from "reactstrap";

import profileImg from "../../assets/images/profile-img.png";
import { AccountContext } from "../../session/userSession";

const Index = (props) => {
  let { history } = props;

  const intialValues = { value: "" };

  const [email, setEmail] = useState({ intialValues });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  let duplicateEmail = { ...email };
  const { sendCode } = useContext(AccountContext);

  const submitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validate(email));
  };
  const validate = (email) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!email.value) {
      errors.email = "Cannot be blank";
    } else if (!regex.test(email.value)) {
      errors.email = "Invalid email format";
    }
    if (Object.keys(errors).length === 0) {
      localStorage.setItem("ezgeyser.forgetPwdEmail", email.value);
      sendCode(email.value);
      history.push("/reset-password");
    }

    return errors;
  };

  return (
    <>
      <div className="account-pages min-vh-100 d-flex justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={7} lg={5} xl={5}>
              <Card className="overflow-hidden mt-4">
                <div className="bg-purple">
                  <Row>
                    <Col className="col-7">
                      <div className="text-white p-4">
                        <h5>Forget password!</h5>
                        <p>Reset with email.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <div className="px-2 py-4">
                  <Form className="form-inline" onSubmit={submitHandler}>
                    <Row className="justify-content-center d-flex mb-3">
                      <div className=" mx-sm-3 col">
                        <Input
                          text={"Enter Your email to get reset code"}
                          label={"Enter email"}
                          width={"w-100"}
                          className={
                            formErrors.email
                              ? "border border-danger w-100 form-control input-style"
                              : " form-control w-100 input-style"
                          }
                          placeholder={"Enter Email"}
                          onChange={(e) => {
                            duplicateEmail.value = e.target.value;
                            setEmail({ ...duplicateEmail });
                          }}
                          type={"text"}
                        />
                        {formErrors.email && (
                          <span className="error text-danger ">
                            {formErrors.email}
                          </span>
                        )}
                      </div>
                    </Row>
                    <div className="mx-sm-3 col">
                      <button
                        className="btn btn-purple btn-block "
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                  </Form>
                </div>
              </Card>
              <div className="text-center mt-5">
                <label>
                  Go Back to &nbsp;
                  <span
                    className="link"
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Login{" "}
                  </span>
                </label>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;

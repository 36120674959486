import Pool from "../../utils/auth/userPool";

import { useState, useContext, useEffect } from "react";
import { Navbar } from "reactstrap";
import Modal from "../modal";
import { AccountContext } from "../../session/userSession";
import axiosInstance from "../../utils/axiosInstance";
import { useHistory } from "react-router";

const Tbar = (props) => {
  let { setShowNav, showNav, profile, setProfile } = props;
  let history = useHistory();

  const { name, updateUserAttributes } = useContext(AccountContext);

  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [profileDetails, setProfileDetails] = useState();
  const [userImage, setUserImage] = useState("");
  const [userNewDetails, setUserNewDetails] = useState("");

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("ezgeyser.userAttributes"));
    setProfileDetails(data);
    getUserMe();
  }, []);

  const handleModal = (title, message) => {
    setModalTitle(title);
    setModalMessage(message);
    setModal(!modal);
    setFormErrors({});
  };

  const confirmHandler = (e) => {
    setFormErrors(validate(profileDetails));
  };

  const validate = (profileDetails) => {
    let errors = {};
    localStorage.setItem(
      "ezgeyser.userAttributes",
      JSON.stringify(profileDetails)
    );
    if (!profileDetails.name) {
      errors.name = "Cannot be blank";
    }
    if (!profileDetails.phone_number) {
      errors.phone_number = "Cannot be blank";
    }
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      const data = {
        name: profileDetails.name,
        phone_number: profileDetails.phone_number,
      };
      axiosInstance
        .post("/users", data)
        .then((res) => {
          setSuccessMessage(res.data.message);
          setIsSubmitting(false);
          setTimeout(() => {
            setSuccessMessage("");
            getUserMe();
            let attributeList = [
              {
                Name: "name",
                Value: profileDetails.name,
              },
              {
                Name: "phone_number",
                Value: profileDetails.phone_number,
              },
            ];
            updateUserAttributes(attributeList);
            setModal(!modal);
          }, 1500);
        })
        .catch((err) => {
          setErrMessage(err.message);
          setIsSubmitting(false);
          setTimeout(() => {
            setErrMessage("");
          }, 1500);
        });
    }
    return errors;
  };

  const getUserMe = () => {
    axiosInstance
      .get(`/users/me`)
      .then((res) => {
        setUserNewDetails(res.data);
        setUserImage(res.data.profile_picture);
        // setName(res.data.name);
      })
      .catch((err) => {
        console.log("err image", err);
      });
  };
  const handleImage = async (e) => {
    setIsSubmitting(true);
    const file = e.target.files[0];
    const base64Image = await getBase64(file);
    setUserImage(base64Image);
    const data = {
      image: base64Image,
    };
    axiosInstance
      .post("/users/profile/upload", data)
      .then((res) => {
        setSuccessMessage("Image Update Successfully");
        setIsSubmitting(false);
        setTimeout(() => {
          setSuccessMessage("");
        }, 1500);
      })
      .catch((err) => {
        setErrMessage(err.message);
        setIsSubmitting(false);
        setTimeout(() => {}, 1500);
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const logout = () => {
    localStorage.removeItem("ezgeyser.JwtToken");
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      history.push("/");
    }
  };


  return (
    <>
      {modal && (
        <Modal
          confirmHandler={confirmHandler}
          message={modalMessage}
          title={modalTitle}
          cancelHandler={handleModal}
          errMessage={errMessage}
          successMessage={successMessage}
          isSubmitting={isSubmitting}
          formErrors={formErrors}
          profileDetails={profileDetails}
          setProfileDetails={setProfileDetails}
          handleImage={handleImage}
          userImage={userImage}
        />
      )}
      <Navbar className="p-3 top-navbar w-100 " expand="md">
        <span
          onClick={() => {
            setShowNav(!showNav);
          }}
        >
          <i className="fa fa-bars text-white" aria-hidden="true"></i>
        </span>

        <div
          className="d-flex align-items-center position-relative user-profile"
          onClick={() => {
            setProfile(!profile);
          }}
        >
          <div className="px-2">
            {userImage ? (
              <img
                src={userImage}
                alt="avatar"
                className="user-avatar rounded-circle"
              />
            ) : (
              <img
                src="https://www.w3schools.com/howto/img_avatar.png"
                alt="user-avatar"
                className="user-avatar rounded-circle"
              />
            )}
          </div>
          <div className="text-light d-flex align-items-center ">
            {/* {name ? (
              <>{name ? name : userNewDetails.name}</> */}
            {userNewDetails ? (
              <>{userNewDetails ? userNewDetails.name : profileDetails.name}</>
            ) : (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
            <i className="fa fa-angle-down px-2 pt-1" aria-hidden="true"></i>
          </div>
          {profile && (
            <div className="position-absolute usvalues mt-5 w-100">
              <div className="mt-4 bg-light px-2 py-1 rounded shadow-sm user-profile-actions">
                <div
                  className="py-1 px-2 rounded"
                  onClick={() => {
                    handleModal("User Profile", "profile");
                  }}
                >
                  Profile
                </div>
                <div
                  className="py-1 px-2 rounded"
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </div>
              </div>
            </div>
          )}
        </div>
      </Navbar>
    </>
  );
};

export default Tbar;

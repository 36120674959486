import { useEffect, useContext } from "react";
import axiosInstance from "../../utils/axiosInstance";

import Meter from "../meter/index";
import Dropdown from "../dropDown";
import List from "../list";
import Weekdays from "../checkBox";
import TimeField from "../timePicker";

import { AccountContext } from "../../session/userSession";

import { Input, Label, Form, Row } from "reactstrap";

const Index = ({
  confirmHandler,
  message,
  title,
  cancelHandler,
  newDevice,
  setNewDevice,
  formErrors,
  isSubmitting,
  errMessage,
  successMessage,
  schedule,
  setSchedule,
  setTimer,
  timer,
  setInviteUserEmail,
  inviteUserHandler,
  deviceID,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  profileDetails,
  setProfileDetails,
  handleImage,
  userImage,
  setUserList,
  userList,
  deleteShareDevice,
}) => {
  const { setName } = useContext(AccountContext);

  let duplicateSchedule = { ...schedule };
  let duplicateNewDevice = { ...newDevice };
  let duplicateProfileDetails = { ...profileDetails };

  useEffect(() => {
    if (message === "Share This Device") {
      getDeviceUserList();
    }
  }, []);

  const getDeviceUserList = () => {
    axiosInstance.get(`/users/devices`).then((res) => {
      const result = res.data.filter((item) => item.DeviceID === deviceID);
      setUserList(result[0].userList);
    });
  };

  // const deleteShareDevice = (email) => {
  //   const data = {
  //     DeviceID: deviceID,
  //     email: email,
  //   };
  //   axiosInstance
  //     .post("/users/devices/share/delete", data)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         const usersList = [...userList];
  //         const index = usersList.findIndex((item) => item?.email === email);
  //         usersList.splice(index, 1);
  //         setUserList(usersList);
  //       }
  //       console.log("res", res.data);
  //     })
  //     .catch((err) => console.log("err", err));
  // };

  const renderMessage = () => {
    if (message === "userList") {
      return (
        <div className="modal-body">
          <List
            viewAction={() => {
              console.log("helo to list");
            }}
            listHeading={"Users"}
            colOneHeading={"Name"}
            colTwoHeading={"Email"}
            colFourHeading={"Actions"}
            placeholder={"Search User Name"}
          />
        </div>
      );
    } else if (message === "Current Settings") {
      return (
        <div className="modal-body">
          Do you want to reset your Current Settings ?
        </div>
      );
    }
    // else if (message === "add User") {
    //   return (
    //     <div className="p-2">
    //       <Form
    //         className="form-horizontal"
    //         onSubmit={(e) => {
    //           // submitHandler(e);
    //         }}
    //       >
    //         <div className="mb-3">
    //           <Label className="form-label">Email</Label>
    //           <Input
    //             className="form-control"
    //             placeholder="Enter email"
    //             type="email"
    //             autoComplete="off"
    //           />
    //         </div>
    //         <div className="mb-1">
    //           <Label className="form-label">Password</Label>
    //           <Input
    //             className="form-control"
    //             placeholder="Enter Password"
    //             autoComplete="off"
    //             type="password"
    //           />
    //         </div>
    //         <div className="mt-3 d-grid">
    //           <button
    //             className="btn btn-purple btn-block "
    //             type="submit"
    //             // disabled={isSubmitting}
    //           >
    //             {/* {!isSubmitting ? ( */}
    //               {/* <> */}
    //               Send Invitation
    //               {/* </> */}
    //             {/* ) : ( */}
    //               {/* <>
    //                 <span
    //                   className="spinner-border spinner-border-sm"
    //                   role="status"
    //                   aria-hidden="true"
    //                 ></span>
    //                 Loading...
    //               </> */}
    //             {/* )} */}
    //           </button>
    //         </div>
    //       </Form>
    //     </div>
    //   );
    // }
    else if (message === "Schedule") {
      return (
        <>
          {errMessage && (
            <Row className="justify-content-center alert-fail border-success rounded mx-5 mt-2 d-flex p-2">
              <span className="text-danger text-center">{errMessage}</span>
            </Row>
          )}
          {successMessage && (
            <Row className="justify-content-center alert-success  border-success rounded mx-5 mt-2 d-flex p-2">
              <span className="text-success  text-center">
                {successMessage}
              </span>
            </Row>
          )}
          <div className="modal-body pt-1 overflow-x-auto">
            <div>
              <Label>
                Tag Name:<span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Name"
                onChange={(e) => {
                  duplicateSchedule.title = e.target.value;
                  setSchedule({ ...duplicateSchedule });
                }}
              />
              {formErrors && (
                <span className="error text-danger ">{formErrors.title}</span>
              )}
            </div>
            <div className="pt-2 d-flex">
              <div>
                <Label>
                  Start Time:<span className="text-danger">*</span>
                </Label>
                <TimeField time={startTime} setTime={setStartTime} />
                {/* <Input
                  type="time"
                  className="form-control"
                  id="appt"
                  name="appt"
                  onChange={(e) => {
                    duplicateSchedule.startTime = e.target.value;
                    setSchedule({ ...duplicateSchedule });
                  }}
                />
                {formErrors && (
                  <span className="error text-danger ">
                    {formErrors.startTime}
                  </span>
                )} */}
              </div>
              <div className="px-4">
                <Label>
                  End Time:<span className="text-danger">*</span>
                </Label>
                <TimeField time={endTime} setTime={setEndTime} />
                {formErrors && (
                  <span className="error text-danger ">
                    {formErrors.endTime}
                  </span>
                )}
                {/* <Input
                  type="time"
                  className="form-control "
                  id="appt"
                  name="appt"
                  onChange={(e) => {
                    duplicateSchedule.endTime = e.target.value;
                    setSchedule({ ...duplicateSchedule });
                  }}
                />
                */}
              </div>
            </div>
            <div className="col pt-2">
              <Label className="p-0 m-0">
                Select Temperature:<span className="text-danger">*</span>
              </Label>
              <div>
                <Meter setSchedule={setSchedule} schedule={schedule} />
                {formErrors && (
                  <span className="error text-danger ">
                    {formErrors.temperature}
                  </span>
                )}
              </div>
            </div>
            <div className="">
              <Label className="">
                Choose Days:<span className="text-danger">*</span>
              </Label>
              <Weekdays schedule={schedule} setSchedule={setSchedule} />
              {formErrors && (
                <span className="error text-danger ">
                  {formErrors.repeating}
                </span>
              )}
            </div>
          </div>
        </>
      );
    } else if (message === "Add timer") {
      return (
        <>
          {errMessage && (
            <Row className="justify-content-center alert-fail border-success rounded mx-5 mt-2 d-flex p-2">
              <span className="text-danger text-center">{errMessage}</span>
            </Row>
          )}
          {successMessage && (
            <Row className="justify-content-center alert-success  border-success rounded mx-5 mt-2 d-flex p-2">
              <span className="text-success  text-center">
                {successMessage}
              </span>
            </Row>
          )}
          <div className="modal-body pt-3">
            <div className="d-flex w-100">
              <div className="w-100">
                <Dropdown setTimer={setTimer} timer={timer} />
              </div>
            </div>
            <div className="col pt-3">
              <Label className="p-0 m-0">
                Select Temperature:<span className="text-danger">*</span>
              </Label>
              <div>
                <Meter />
              </div>
            </div>
          </div>
        </>
      );
    } else if (message === "Add Device") {
      return (
        <>
          {errMessage && (
            <Row className="justify-content-center alert-fail border-success rounded mx-5 mt-2 d-flex p-2">
              <span className="text-danger text-center">{errMessage}</span>
            </Row>
          )}
          {successMessage && (
            <Row className="justify-content-center alert-success  border-success rounded mx-5 mt-2 d-flex p-2">
              <span className="text-success  text-center">
                {successMessage}
              </span>
            </Row>
          )}
          <div className="modal-body d-flex justify-content-between">
            <Form className="form-inline">
              <Row className="justify-content-center d-flex mb-3">
                <div className=" mx-sm-3 col">
                  <Label className="form-label">
                    Device Name:<span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={
                      formErrors.deviceName
                        ? "border border-danger form-control input-style"
                        : " form-control input-style"
                    }
                    placeholder="Enter Device Name"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      duplicateNewDevice.deviceName = e.target.value;
                      setNewDevice({ ...duplicateNewDevice });
                    }}
                  />
                  {formErrors && (
                    <span className="error text-danger ">
                      {formErrors.deviceName}
                    </span>
                  )}
                </div>
                <div className=" mx-sm-3 col">
                  <Label className="form-label">
                    Mac Address:<span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={
                      formErrors.macAddress
                        ? "border border-danger form-control input-style"
                        : " form-control input-style"
                    }
                    placeholder="Enter Mac Address"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      duplicateNewDevice.macAddress = e.target.value;
                      setNewDevice({ ...duplicateNewDevice });
                    }}
                  />
                  {formErrors && (
                    <span className="error text-danger ">
                      {formErrors.macAddress}
                    </span>
                  )}
                </div>
              </Row>
              <Row className="justify-content-center d-flex mb-3">
                <div className=" mx-sm-3 col">
                  <Label className="form-label">
                    Default Temperature:<span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={
                      formErrors.defaultTemperature
                        ? "border border-danger form-control input-style"
                        : " form-control input-style"
                    }
                    placeholder="Enter Temperature"
                    type="number"
                    autoComplete="off"
                    onChange={(e) => {
                      e.target.value = e.target.value.slice(0, 2);
                      duplicateNewDevice.defaultTemperature = e.target.value;
                      setNewDevice({ ...duplicateNewDevice });
                    }}
                  />
                  {formErrors && (
                    <span className="error text-danger ">
                      {formErrors.defaultTemperature}
                    </span>
                  )}
                </div>
                <div className=" mx-sm-3 col"></div>
              </Row>
            </Form>
          </div>
        </>
      );
    } else if (message === "profile") {
      return (
        <>
          {successMessage && (
            <Row className="justify-content-center alert-success  border-success rounded mx-5 mt-2 d-flex p-2">
              <span className="text-success  text-center">
                {successMessage}
              </span>
            </Row>
          )}
          {errMessage && (
            <Row className="justify-content-center alert-fail border-success rounded mx-5 mt-2 d-flex p-2">
              <span className="text-danger text-center">{errMessage}</span>
            </Row>
          )}
          <div className="modal-body d-flex justify-content-between">
            <Form className="form-inline">
              <Row className="justify-content-center d-flex mb-3">
                <div className=" mx-sm-3 col">
                  <Label className="form-label mb-1">
                    Full Name:<span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={
                      formErrors.name
                        ? "border border-danger form-control input-style"
                        : " form-control input-style"
                    }
                    placeholder="Enter Full Name"
                    type="text"
                    value={profileDetails.name}
                    autoComplete="off"
                    onChange={(e) => {
                      duplicateProfileDetails.name = e.target.value;
                      setProfileDetails({ ...duplicateProfileDetails });
                      
                    }}
                  />
                  {formErrors && (
                    <span className="error text-danger ">
                      {formErrors.name}
                    </span>
                  )}
                </div>
                <div className=" mx-sm-3 col">
                  <Label className="form-label mb-1">
                    Phone Number:<span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={
                      formErrors.phone_number
                        ? "border border-danger form-control input-style"
                        : " form-control input-style"
                    }
                    placeholder="Enter Phone Number"
                    type="text"
                    autoComplete="off"
                    value={profileDetails.phone_number}
                    onChange={(e) => {
                      duplicateProfileDetails.phone_number = e.target.value;
                      setProfileDetails({ ...duplicateProfileDetails });
                    }}
                  />
                  {formErrors && (
                    <span className="error text-danger ">
                      {formErrors.phone_number}
                    </span>
                  )}
                </div>
              </Row>
              <Row>
                <div className=" mx-sm-3 col">
                  <Label className="form-label mb-1">Upload Image:</Label>
                  <label
                    htmlFor="formId"
                    className="border image-conatiner d-flex justify-content-center align-items-center pointer"
                    onChange={(e) => {
                      handleImage(e);
                    }}
                  >
                    <input name="" type="file" id="formId" hidden />
                    {userImage ? (
                      <img
                        src={userImage}
                        alt="user-avatar"
                        className="w-100 h-100"
                      />
                    ) : (
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    )}
                  </label>
                </div>
              </Row>
            </Form>
          </div>
        </>
      );
    } else if (message === "Deleteitem") {
      return (
        <>
          {successMessage && (
            <Row className="justify-content-center alert-success  border-success rounded mx-5 mt-2 d-flex p-2">
              <span className="text-success  text-center">
                {successMessage}
              </span>
            </Row>
          )}
          {errMessage && (
            <Row className="justify-content-center alert-fail border-success rounded mx-5 mt-2 d-flex p-2">
              <span className="text-danger text-center">{errMessage}</span>
            </Row>
          )}
          <div className="modal-body d-flex justify-content-between">
            Do you want to Delete ?
          </div>
        </>
      );
    } else if (message === "Share This Device") {
      return (
        <>
          {successMessage && (
            <Row className="justify-content-center alert-success  border-success rounded mx-5 mt-2 d-flex p-2">
              <span className="text-success  text-center">
                {successMessage}
              </span>
            </Row>
          )}
          {errMessage && (
            <Row className="justify-content-center alert-fail border-success rounded mx-5 mt-2 d-flex p-2">
              <span className="text-danger text-center">{errMessage}</span>
            </Row>
          )}
          <div className="modal-body">
            <Row className="justify-content-center d-flex mb-3">
              <div className=" mx-sm-3 w-100">
                <Label className="form-label mb-1">
                  Enter Email:<span className="text-danger">*</span>
                </Label>
                <Input
                  className={
                    formErrors.inviteUserEmail
                      ? "border border-danger form-control input-style"
                      : " form-control input-style"
                  }
                  placeholder="Enter Email"
                  type="text"
                  autoComplete="off"
                  // value={userDat.name}
                  onChange={(e) => {
                    setInviteUserEmail(e.target.value.replace(/\s/g, ''));
                  }}
                />
                {formErrors && (
                  <span className="error text-danger ">
                    {formErrors.inviteUserEmail}
                  </span>
                )}
              </div>
            </Row>
            {userList && userList.length > 0 ? (
              <>
                <div className="pt-3">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">User Name</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList.map((item) => (
                        <>
                          <tr key={item.UserID}>
                            <td>{item.email}</td>
                            <td>
                              <i
                                className="fa fa-trash"
                                aria-hidden="true"
                                onClick={() => {
                                  deleteShareDevice(item.email);
                                }}
                              ></i>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </>
      );
    } else {
      return <div className="modal-body">{message} </div>;
    }
  };

  return (
    <div className="position-fixed modal-container">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {title}
            </h5>
            <h5 onClick={cancelHandler} role="button">
              &times;
            </h5>
          </div>
          {renderMessage()}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={cancelHandler}
            >
              Close
            </button>
            {( !(message === "Schedule" && errMessage === "Can't add more schedule for this device" )) && 
            <button
            className="btn btn-purple btn-block "
            type="submit"
            disabled={isSubmitting}
            onClick={() => {
              if (message === "Share This Device") {
                inviteUserHandler();
              } else {
                confirmHandler();
              }
            }}
          >
            {!isSubmitting ? (
              <>Save</>
            ) : (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </>
            )}
          </button>
            }       
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

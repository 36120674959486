import React, { useState, useEffect, useContext } from "react";

import { AccountContext } from "../../session/userSession";
import axiosInstance from "../../utils/axiosInstance";
import { withRouter } from "react-router-dom";

import { useHistory } from "react-router";

import Modal from "../../components/modal";

import Loader from "../../components/loader";
import Tiles from "./tiles";
import List from "../../components/list";
import Layout from "../../components/layout";

import { Col } from "reactstrap";

export const Devices = (props) => {
  // let { history } = props;
  let history = useHistory();

  const [deleteId, setDeleteId] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { getSession, setAllDevices, allDevices, setListItems, listItems, setLoader } =
    useContext(AccountContext);

  useEffect(() => {
    const user = localStorage.getItem("ezgeyser.JwtToken");
    if (user) {
      setListItems("");
      getingDevices(user);
      getSession();
    }
    else {
      history.push("/");
    }
  }, []);

  const getingDevices = (user) => {
    setLoader(true);
    console.log("this is running" , user)
    axiosInstance
      .get(`/users/devices`)
      .then((res) => {
        setListItems(res.data);
      setAllDevices(res.data);
      setLoader(false);
          return true;
      })
      .catch((err) => {
        console.log("this is error",err)
        localStorage.removeItem("ezgeyser.JwtToken");
        localStorage.removeItem("ezgeyser.userAttributes");
        localStorage.removeItem("ezgeyser.Authuser");
        history.push("/");
        return false;
      });
    return false;
  };

  const viewListItem = (id) => {
    history.push(`/device-data/${id}`);
  };

  const deleteDevice = () => {
    setIsSubmitting(true);
    axiosInstance
      .delete(`/users/devices/${deleteId}`)
      .then((res) => {
        if (res.status === 200) {
          setSuccessMessage("Device Deleted successfully");
          const result = listItems.filter(
            (item) => item.DeviceID !== deleteId
          );
          setListItems(result);
          setTimeout(() => {
            setIsSubmitting(false);
            setSuccessMessage("");
            setModal(!modal);
          }, 1500);

          return true;
        }
      })
      .catch((err) => {
        setErrMessage(err.message);
        setTimeout(() => {
          setErrMessage("");
        }, 1500);
        return false;
      });
    return false;
  };
  const deleteItem = (title, message) => {
    setModalTitle(title);
    setModalMessage(message);
    setModal(!modal);
  };


  return (
    <>
      {allDevices ? (
        <Layout>
          {modal && (
            <Modal
              message={modalMessage}
              title={modalTitle}
              cancelHandler={() => {
                setModal(!modal);
              }}
              deleteId={deleteId}
              isSubmitting={isSubmitting}
              errMessage={errMessage}
              successMessage={successMessage}
              confirmHandler={deleteDevice}
            />
          )}
          <div className="overflow-x-hidden w-100 mt-0 ">
            <div className="overflow-x-hidden child-screen">
              <Col md={12} lg={12} xl={12} className="px-0">
                <Tiles allDevices={allDevices} />
              </Col>
              <Col
                md={12}
                lg={12}
                xl={12}
                className=" px-4 mt-4 run-dev-container"
              >
                <List
                  viewAction={(id) => {
                    viewListItem(id);
                  }}
                  listHeading={"Devices"}
                  colOneHeading={"Geyser Name"}
                  colTwoHeading={"Current Temperature"}
                  colThreeHeading={"Geyser Status"}
                  colFourHeading={"Actions"}
                  placeholder={"Search Device Name"}
                  // devices={allDevices}
                  setDeleteId={setDeleteId}
                  deleteItem={deleteItem}
                />
              </Col>
            </div>
          </div>
        </Layout>
      ) : (
        <div className="min-vh-100 d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      )}
    </>
  );
};

export default withRouter(Devices);
